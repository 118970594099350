/* eslint-disable import/no-cycle */
import { BookType, EditionType } from "types";
import Link from "next/link";
import {
  CoverLinkProps as CoverComponentLinkProps,
  CoverProps,
  coverThemeLink,
} from "hardcover-ui/theme/components/cover";
import classNames from "lib/classNames";
import { primaryContributionNames } from "lib/contributions";
import CoverImage from "./CoverImage";
import HandleCoverLongPress from "./HandleCoverLongPress";

export interface CoverLinkProps {
  book: BookType;
  edition?: EditionType;
  size: CoverProps["size"];
  variant?: CoverComponentLinkProps["variant"];
  settings?: CoverProps["settings"];
  className?: string;
}

const DefaultSettings = {
  tooltip: true,
  details: true,
  eager: false,
};

export default function CoverLink({
  book,
  edition,
  size,
  variant = "none",
  settings = DefaultSettings,
  className = "",
}: CoverLinkProps) {
  const finalClassName = classNames(
    coverThemeLink.variant[variant],
    coverThemeLink.size?.[size],
    className,
    "block"
  );
  const finalSettings = {
    ...settings,
    border: false,
  };
  const tooltip: any = {};
  if (settings.tooltip) {
    tooltip["data-tooltip-id"] = "tooltip";
    tooltip["data-tooltip-html"] = `<b>${book.title}</b>${
      book.releaseYear ? ` (${book.releaseYear})` : ""
    }`;
    if (book.contributions?.length > 0) {
      const authors = primaryContributionNames(book.contributions);
      if (authors.length > 0) {
        tooltip[
          "data-tooltip-html"
        ] += `<br /><span class="opacity-80">By ${authors.join(", ")}</span>`;
      }
    }
  }

  const url = edition
    ? `/books/${book.slug}/editions/${edition.id}`
    : `/books/${book.slug}`;

  return (
    <Link href={url} className={finalClassName} {...tooltip}>
      <HandleCoverLongPress bookId={book.id}>
        <CoverImage
          id={book.id}
          image={
            edition?.cachedImage?.url ? edition.cachedImage : book.cachedImage
          }
          size={size}
          title={book.title}
          variant="none"
          settings={finalSettings}
        />
      </HandleCoverLongPress>
    </Link>
  );
}
