import { gql } from "graphql-tag";
import { BookType } from "types";

export const findBookById = gql`
  query FindBookById($id: Int!) {
    book: books_by_pk(id: $id) {
      ...BookFragment
    }
  }
`;

export interface FindBookByIdData {
  book: BookType;
}
