"use client";

/* eslint-disable import/no-cycle */
import { ContributionType } from "types";
import { useState } from "react";
import { AvatarThemeProps } from "hardcover-ui/theme/components/avatar";
import { Contribution } from "./ContributionsList";

interface Props {
  contributions: ContributionType[];
  link?: boolean;
  avatar?: boolean;
  lazy?: boolean;
  separator?: string;
  size?: AvatarThemeProps["size"];
  uid?: string;
}

export default function MoreContributions({
  contributions,
  link,
  avatar,
  lazy,
  separator,
  uid,
  size,
}: Props) {
  const [expanded, setExpanded] = useState(false);

  if (!contributions || contributions.length === 0) {
    return <></>;
  }

  if (!expanded) {
    return (
      <button
        type="button"
        onClick={() => setExpanded(true)}
        className="underline hover:no-underline"
      >
        +{contributions.length} more
      </button>
    );
  }
  return (
    <>
      {contributions.map((contribution, index) => (
        <Contribution
          contribution={contribution}
          link={link}
          avatar={avatar}
          key={`contribution-${uid}-${contribution.author.slug}`}
          size={size}
          lazy={lazy}
          separator={separator}
          isLast={index === contributions.length - 1}
        />
      ))}
    </>
  );
}
