"use client";

/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import classNames from "lib/classNames";
import { useCallback, useMemo, useState } from "react";

type ExpandingTextProps = {
  text: string;
  lines: number;
  className?: string;
  isTextExpandedFromOutside?: boolean;
};

const CHAR_PER_LINE = 45 * 2;

// line-clamp-1 line-clamp-2 line-clamp-3 line-clamp-4 line-clamp-5 line-clamp-6
export default function ExpandingText({
  text = "",
  lines = 3,
  className = "",
  isTextExpandedFromOutside = false,
}: ExpandingTextProps) {
  const [isExpanded, setExpanded] = useState(
    isTextExpandedFromOutside || text.length < CHAR_PER_LINE * lines
  );

  const htmlText = useMemo(() => {
    return (text || "")
      .replaceAll("\n\n", "<br/><br/>")
      .replaceAll("\r\n\r\n", "<br/><br/>")
      .replaceAll("\r\n", "<br/>")
      .replaceAll("\n", "<br/>");
  }, [text]);

  const toggleExpanded = useCallback(() => {
    setExpanded((e) => !e);
  }, []);

  if (!text || text.length === 0) {
    return <></>;
  }

  return (
    <div className={`relative max-w-screen overflow-hidden group ${className}`}>
      {!isExpanded && (
        <button
          type="button"
          className="cursor pointer absolute z-10 h-full w-full bg-gradient-to-b from-transparent to-white dark:to-gray-800 via-transparent via-70%"
          onClick={toggleExpanded}
          aria-label="Expand text"
        />
      )}
      <p
        aria-label="Show text"
        className={classNames(
          "lg:text-left",
          isExpanded ? "" : `line-clamp-${lines}`,
          !isExpanded
            ? "group-hover:bg-gray-100 dark:group-hover:bg-gray-900 rounded-lg"
            : ""
        )}
        dangerouslySetInnerHTML={{
          __html: htmlText,
        }}
      />
    </div>
  );
}
