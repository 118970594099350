"use client";

import { useFragment } from "@apollo/experimental-nextjs-app-support/ssr";
import useMount from "hooks/useMount";
import EditionFragmentCompiled from "queries/editions/fragments/EditionFragmentCompiled";
import { useMemo } from "react";
import { EditionType } from "types";

export default function useEditionFragment({
  editionId,
  edition: initialEdition,
}: {
  editionId: number;
  edition?: EditionType;
}) {
  const mounted = useMount();
  // /////////////////////////
  // Load data about this edition
  // /////////////////////////
  const { data: edition } = useFragment({
    fragment: EditionFragmentCompiled,
    fragmentName: "EditionFragment",
    from: {
      __typename: "editions",
      id: editionId,
    },
  });

  return useMemo(() => {
    return (
      mounted ? (edition?.id ? edition : null) : initialEdition
    ) as EditionType;
  }, [edition, initialEdition, mounted]);
}
