"use client";

import { useEffect, useState } from "react";
import { PencilAltIcon } from "@heroicons/react/outline";
import Button from "hardcover-ui/components/Button";
import { getUserId } from "store/currentUser/currentUserSelector";
import { useSelector } from "react-redux";

export default function EditProfileButton({ userId }: { userId: number }) {
  const [showEditProfileButton, setShowEditProfileButton] = useState(false);
  const currentUserId = useSelector(getUserId);

  useEffect(() => {
    setShowEditProfileButton(userId === currentUserId);
  }, [currentUserId]);

  if (showEditProfileButton) {
    return (
      <Button
        variant="accent"
        size="sm"
        as={{
          element: "link",
          props: {
            href: "/account/profile",
          },
        }}
      >
        <PencilAltIcon className="w-4" />
        <span className="">Edit</span>
      </Button>
    );
  }

  return <></>;
}
