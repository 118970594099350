import { gql } from "graphql-tag";

export const insertBlock = gql`
  mutation CreateBan($blockedUserId: Int!) {
    blockResponse: insert_block(blocked_user_id: $blockedUserId) {
      error
      userBlock: user_block {
        ...UserBlockFragment
      }
    }
  }
`;
