"use client";

import { useEffect } from "react";
import { getClient } from "lib/apollo/client";
import { gql } from "graphql-tag";
import { CacheQueryType } from "types/CacheQueryType";

export default function WriteFragment({
  data,
  query,
  variables,
}: CacheQueryType) {
  useEffect(() => {
    getClient().writeQuery({
      query: gql(query),
      data,
      variables,
    });
  }, []);

  return false;
}
