"use client";

import { lazy, useEffect, useState, Suspense } from "react";
import { getUserId } from "store/currentUser/currentUserSelector";
import { useSelector } from "react-redux";
import { UserType } from "types";

const LoggedInFollowButton = lazy(() => import("./LoggedInFollowButton"));

export default function FollowUserButton({ user }: { user: UserType }) {
  const currentUserId = useSelector(getUserId);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    setShowButton(currentUserId && user.id !== currentUserId);
  }, [currentUserId, user?.id]);

  if (!showButton) {
    return <></>;
  }

  return (
    <Suspense>
      <LoggedInFollowButton user={user} currentUserId={currentUserId} />
    </Suspense>
  );
}
