"use client";

import { useState } from "react";
import Link from "next/link";
import UserType from "types/UserType";
import classNames from "lib/classNames";
import StatusListType from "types/StatusListType";
import pluralize from "lib/pluralize";
import Button from "hardcover-ui/components/Button";
import CoverLink from "components/Cover/CoverLink";
import { UserBookType } from "types";
import useEdition from "hooks/fragments/useEditionFragment";
import useUserBookFragment from "services/UserBookService/useUserBookFragment";
import useBookFragment from "hooks/fragments/useBookFragment";

export interface Props {
  user: UserType;
  lists: StatusListType[];
}

function UserBookCoverLink({
  userBook: initialUserBook,
}: {
  userBook: UserBookType;
}) {
  const userBook = useUserBookFragment({
    userBook: initialUserBook,
    userId: initialUserBook.userId,
    bookId: initialUserBook.bookId,
  });
  const edition = useEdition({
    editionId: userBook?.editionId || initialUserBook.editionId,
    edition: userBook?.edition || initialUserBook.edition,
  });
  const book = useBookFragment({
    bookId: userBook?.bookId || initialUserBook.bookId,
    book: userBook.book || initialUserBook.book,
  });

  if (!userBook || !book) {
    return false;
  }

  return (
    <CoverLink
      book={book}
      size="md"
      variant="secondary"
      edition={edition}
      settings={{
        showStatus: true,
        tooltip: true,
        details: true,
      }}
    />
  );
}

export default function UserBooksByStatusTopPreviewView({
  user,
  lists,
}: Props) {
  const [activeList, setActiveList] = useState<StatusListType | null>(lists[0]);

  if (lists.length === 0) {
    return false;
  }

  return (
    <div className="grid grid-cols-3 w-full border rounded-lg divide-x dark:border-gray-700 divide-secondary overflow-hidden">
      <div className="flex flex-col divide-y divide-secondary col-span-3 md:col-span-1">
        {lists.map((list) => (
          <Button
            key={`profile-status-lists-${list.id}`}
            onClick={(e) => {
              e.preventDefault();
              setActiveList(list);
            }}
            className={classNames(
              "flex justify-between p-3 text-base",
              activeList && activeList.id === list.id
                ? "bg-yellow-400 text-yellow-900 font-bold"
                : "hover:bg-stone-300 dark:hover:bg-gray-700"
            )}
          >
            {list.status}
            <span
              className={classNames(
                "flex gap-1 items-center px-2 py-0.5 rounded-full ",
                activeList && activeList.id === list.id
                  ? "bg-yellow-900 text-yellow-400"
                  : "bg-stone-200 dark:bg-gray-600"
              )}
            >
              {list.booksCount.toLocaleString()}
            </span>
          </Button>
        ))}
      </div>

      <div className="border-t border-gray-200 dark:border-gray-700 md:border-none flex flex-col justify-between col-span-3 md:col-span-2 overflow-hidden bg-gradient-to-b from-transparent to-gray-200 dark:to-gray-600 pb-2">
        <div className="flex justify-between text-gray-400 px-6 py-3 w-full">
          <p>
            {activeList.booksCount.toLocaleString()}{" "}
            {pluralize("Book", activeList.booksCount)}
          </p>
          <Link
            href={`/@${user.username}/books/${activeList.slug}`}
            className="hover:underline"
          >
            See all
          </Link>
        </div>

        <div className="flex gap-2 justify-center items-end">
          {activeList.userBooks.slice(0, 7).map((ub) => (
            <UserBookCoverLink
              key={`active-book-${activeList.id}-${ub.book.id}`}
              userBook={ub}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
