import { gql } from "graphql-tag";

export const findBlock = gql`
  query FindBlock($userId: Int!, $blockedUserId: Int!) {
    userBlocks: user_blocks(
      where: {
        user_id: { _eq: $userId }
        blocked_user_id: { _eq: $blockedUserId }
      }
    ) {
      ...UserBlockFragment
    }
  }
`;
