"use client";

/* eslint-disable import/no-cycle */
import { getUserId } from "store/currentUser/currentUserSelector";
import useMount from "hooks/useMount";
import { Suspense, lazy } from "react";
import { useSelector } from "react-redux";

const ListUserReadCount = lazy(() => import("./ListUserReadCount"));

export type ListUserReadCountLoaderSize = "sm" | "md" | "lg";
export default function ListUserReadCountLoader({
  listId,
  count,
  size = "sm",
}: {
  listId: number;
  count: number;
  size: ListUserReadCountLoaderSize;
}) {
  const userId = useSelector(getUserId);
  const mounted = useMount();

  if (!userId || !mounted) {
    return <></>;
  }

  return (
    <Suspense>
      <ListUserReadCount
        userId={userId}
        listId={listId}
        count={count}
        size={size}
      />
    </Suspense>
  );
}
