"use client";

import { useEffect, useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import Icon from "hardcover-ui/components/Icon";
import faBan from "icons/solid/ban.svg";
import faRefresh from "icons/solid/arrows-rotate.svg";
import Button from "hardcover-ui/components/Button";
import { toast } from "sonner";
import { findBlock } from "queries/user_blocks/getBlock";
import { insertBlock } from "queries/user_blocks/createBlock";
import { deleteBlock } from "queries/user_blocks/deleteBlock";
import UserBlockType from "types/UserBlockType";
import { useSelector } from "react-redux";
import { getUserId } from "store/currentUser/currentUserSelector";

export interface Props {
  userId: number;
  block?: UserBlockType;
}

export default function BlockButton({ userId, block }: Props) {
  const currentUserId = useSelector(getUserId);
  const [currentBlock, setCurrentBlock] = useState<UserBlockType | null>(
    block || null
  );
  const [getBlock, { loading: loadingBlock }] = useLazyQuery(findBlock, {
    onCompleted: (result) => {
      if (result?.userBlocks?.length > 0) {
        setCurrentBlock(result.userBlocks[0]);
      }
    },
  });
  const [createBan, { loading: createLoading }] = useMutation(insertBlock, {
    onCompleted: (result) => {
      setCurrentBlock(result.blockResponse.userBlock);
    },
    onError: () => {
      toast.error(
        "There was a problem blocking this user. Can you refresh and try again?"
      );
    },
  });
  const [destroyBan, { loading: deleteLoading }] = useMutation(deleteBlock, {
    onCompleted: () => {
      setCurrentBlock(null);
    },
    onError: () => {
      toast.error(
        "There was a problem unblocking this user. Can you refresh and try again?"
      );
    },
  });
  useEffect(() => {
    if (!block) {
      getBlock({
        variables: {
          userId: currentUserId,
          blockedUserId: userId,
        },
      });
    }
  }, []);

  const toggleBan = () => {
    if (currentBlock) {
      destroyBan({
        variables: {
          id: currentBlock.id,
        },
      });
    } else {
      createBan({
        variables: {
          blockedUserId: userId,
        },
      });
    }
  };

  if (loadingBlock) {
    return <></>;
  }

  return (
    <Button variant="ghost" size="sm" onClick={toggleBan}>
      <Icon icon={faBan} size="sm" />
      {currentBlock ? "Unblock" : "Block"}
      {(deleteLoading || createLoading) && <Icon icon={faRefresh} size="sm" />}
    </Button>
  );
}
