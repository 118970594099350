"use client";

import {
  useFragment,
  useQuery,
} from "@apollo/experimental-nextjs-app-support/ssr";
import useMount from "hooks/useMount";
import BookFragmentCompiled from "queries/books/fragments/BookFragmentCompiled";
import { findBookById } from "queries/books/getBookById";
import { useMemo } from "react";
import { BookType } from "types";

export default function useBookFragment({
  bookId,
  book: initialBook,
}: {
  bookId: number;
  book?: BookType;
}) {
  const mounted = useMount();
  // /////////////////////////
  // Load data about this book
  // /////////////////////////
  const { data: book, complete } = useFragment({
    fragment: BookFragmentCompiled,
    fragmentName: "BookFragment",
    from: {
      __typename: "books",
      id: bookId || initialBook?.id,
    },
  });

  useQuery(findBookById, {
    // Only run if:
    //   No data about the book
    skip: complete,
    variables: { id: bookId || initialBook?.id },
  });

  return useMemo(() => {
    return (mounted ? (book?.id ? book : null) : initialBook) as BookType;
  }, [book, initialBook, mounted]);
}
