import isDate from "lodash/isDate";

export const dateDiff = (d1: Date, d2: Date) => {
  const differenceTime = d1.getTime() - d2.getTime();

  // To calculate the no. of days between two dates
  const differenceDays = differenceTime / (1000 * 3600 * 24);

  return Math.abs(differenceDays);
};

export const isInPast = (date: string | Date | null) => {
  if (!date) {
    return false;
  }
  const now = new Date();
  now.setHours(0, 0, 0, 0);

  const d = isDate(date) ? date : new Date(date);
  return d < now;
};

// a and b are javascript Date objects
const MS_PER_DAY = 1000 * 60 * 60 * 24;
export function dateDiffInDays(a, b) {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / MS_PER_DAY);
}

function toISOLocal(d) {
  const z = (n) => `0${n}`.slice(-2);
  const zz = (n) => `00${n}`.slice(-3);

  return `${d.getFullYear()}-${z(d.getMonth() + 1)}-${z(d.getDate())}T${z(
    d.getHours()
  )}:${z(d.getMinutes())}:${z(d.getSeconds())}.${zz(d.getMilliseconds())}Z`;
}

export const parsedDate = (date) => {
  if (!date) {
    return null;
  }
  let newDate!: Date;
  if (isDate(date)) {
    newDate = date;
  } else {
    newDate = new Date(`${date}T00:00:00`);
  }
  return toISOLocal(newDate).substring(0, 10);
};

export const parsedDateToDate = (date: null | string | Date) => {
  if (!date) {
    return null;
  }
  let newDate!: Date;
  if (isDate(date)) {
    newDate = date;
  } else {
    newDate = new Date(`${date}T00:00:00`);
  }
  return newDate;
};
