"use client";

import UserBookType from "types/UserBookType";
import { useFragment } from "@apollo/experimental-nextjs-app-support/ssr";
import UserBookFragmentCompiled from "queries/user_books/fragments/UserBookFragmentCompiled";
import useMount from "hooks/useMount";

export default function useUserBookFragment({
  bookId,
  userId,
  userBook: initialUserBook,
}: {
  bookId: number;
  userId: number;
  userBook?: UserBookType;
}) {
  const mounted = useMount();
  const { data } = useFragment({
    fragment: UserBookFragmentCompiled,
    fragmentName: "UserBookFragment",
    from: {
      __typename: "user_books",
      bookId,
      userId: userId,
    },
  });

  return mounted ? (data?.id ? data : null) : initialUserBook;
}
