"use client";

import Icon from "hardcover-ui/components/Icon";
import faFlag from "icons/solid/flag.svg";
import Button from "hardcover-ui/components/Button";
import { getUserId } from "store/currentUser/currentUserSelector";
import BlockButton from "components/users/BlockButton";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useMount from "hooks/useMount";

export default function BlockUserButton({ userId }: { userId: number }) {
  const [showBlockButton, setShowBlockButton] = useState(false);
  const currentUserId = useSelector(getUserId);
  const mounted = useMount();

  useEffect(() => {
    setShowBlockButton(userId !== currentUserId);
  }, [currentUserId]);

  if (!mounted || !currentUserId || !showBlockButton) {
    return <></>;
  }

  return (
    <>
      <Button
        variant="ghost"
        size="md"
        as={{
          element: "link",
          props: {
            href: `/report?type=User&id=${userId}`,
          },
        }}
      >
        <Icon icon={faFlag} size="sm" className="fill-gray-300" />
      </Button>
      <BlockButton userId={userId} />
    </>
  );
}
