/* eslint-disable import/no-cycle */

import { ReactNode } from "react";
import { AuthorType, ContributionType } from "types";
import { isPrimaryContribution, isAuthor } from "lib/contributions";
import Text from "hardcover-ui/components/Text";
import Avatar from "hardcover-ui/components/Avatar";
import { AvatarThemeProps } from "hardcover-ui/theme/components/avatar";
import uniqBy from "lodash/uniqBy";
import MoreContributions from "./MoreContributions";
import TextLink from "hardcover-ui/components/TextLink";

export interface Props {
  contributions: ContributionType[];
  link?: boolean;
  className?: string;
  primary?: boolean;
  byLine?: boolean;
  avatar?: boolean;
  size?: AvatarThemeProps["size"];
  lazy?: boolean;
  separator?: string;
  uid?: string;
  max?: number;
  expand?: boolean;
}

const ContributionsListWrapper = ({
  children,
  byLine,
}: {
  children: ReactNode;
  byLine: boolean;
}) => {
  return (
    <span className="flex-inline flex-row flex-wrap leading-5">
      {byLine && (
        <Text variant="inherit" className="mr-1">
          By
        </Text>
      )}
      {children}
    </span>
  );
};

const ContributionWrapperClass = "flex-inline flex-row items-center";
const ContributionWrapper = ({
  children,
  author,
  link,
}: {
  children: ReactNode;
  author: AuthorType;
  link: boolean;
}) => {
  if (link) {
    return (
      <TextLink
        href={`/authors/${author.slug}`}
        className={ContributionWrapperClass}
      >
        {children}
      </TextLink>
    );
  }
  return <span className={ContributionWrapperClass}>{children}</span>;
};
const Contribution = ({
  contribution,
  avatar,
  link,
  size,
  lazy,
  separator = ",",
  isLast,
}: {
  contribution: ContributionType;
  avatar: boolean;
  link: boolean;
  size?: AvatarThemeProps["size"];
  lazy?: boolean;
  separator?: string;
  isLast: boolean;
}) => {
  return (
    <span className="flex-inline flex-row mr-1">
      {avatar && (
        <Avatar
          image={contribution.author.cachedImage}
          size={size}
          alt={contribution.author.name}
          variant="bordered"
          className="mr-1 align-middle"
          lazy={lazy}
          inline
        />
      )}
      <ContributionWrapper author={contribution.author} link={link}>
        <span>{contribution.author.name}</span>
        {contribution.contribution && !isAuthor(contribution) && (
          <Text variant="secondary" className="ml-1 font-normal">
            ({contribution.contribution})
          </Text>
        )}
      </ContributionWrapper>
      {!isLast && <>{separator}</>}
    </span>
  );
};
export { Contribution };

// Todo: Cleam this up
const ContributionsList = ({
  className = "",
  contributions,
  link = true,
  primary = true,
  byLine = false,
  avatar = false,
  size = "sm",
  lazy = true,
  uid = "",
  max = 2,
  separator = ",",
  expand = false,
}: Props) => {
  if (!contributions || contributions.length === 0) {
    return <></>;
  }

  const uniqContributions = uniqBy(
    contributions.filter(
      (contribution) => !primary || isPrimaryContribution(contribution)
    ),
    (contribution) => contribution.author.slug
  );

  let filteredContributions =
    uniqContributions.length > 0 ? uniqContributions : contributions;

  filteredContributions = filteredContributions.slice(0, max);
  const moreContributions = uniqContributions.slice(
    max,
    uniqContributions.length
  );

  return (
    <div className={className}>
      <ContributionsListWrapper byLine={byLine}>
        {filteredContributions.map((contribution, index) => (
          <Contribution
            contribution={contribution}
            link={link}
            avatar={avatar}
            key={`contribution-${uid}-${contribution.author.slug}`}
            size={size}
            lazy={lazy}
            separator={separator}
            isLast={
              index === filteredContributions.length - 1 &&
              moreContributions.length === 0
            }
          />
        ))}
        {uniqContributions.length > max ? (
          <>
            {expand ? (
              <MoreContributions
                contributions={moreContributions}
                uid={uid}
                link={link}
                avatar={avatar}
                size={size}
                lazy={lazy}
                separator={separator}
              />
            ) : (
              <span>+{uniqContributions.length - max} more</span>
            )}
          </>
        ) : (
          false
        )}
      </ContributionsListWrapper>
    </div>
  );
};

export default ContributionsList;
