"use client";

import { getUserId } from "store/currentUser/currentUserSelector";
import useMount from "hooks/useMount";
import { Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import { UserType } from "types";

const ShowPinOptionToOwner = lazy(() => import("./ShowPinOptionToOwner"));

export default function ProfilePinOwnerCallout({ user }: { user: UserType }) {
  const currentUserId = useSelector(getUserId);
  const mounted = useMount();

  if (!mounted || user.id !== currentUserId) {
    return <></>;
  }

  // Current user viewing their own blank profile.
  return (
    <Suspense>
      <ShowPinOptionToOwner />
    </Suspense>
  );
}
